import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

// Pages
import NoPage from "./pages/NoPage";
// import LandingPage from "./pages/LandingPage";
import IndemniIOSLandingPage from "./pages/IndemniIOSLanding";
import IndemniIOSAboutPage from "./pages/IndemniIOSAbout";
// import AskIndemniExplorePage from "./pages/AskIndemniExplorePage";
// import AskADevExplorePage from "./pages/AskADevExplorePage";
// import FAQPage from "./pages/FAQPage";
// import SettingsPage from "./pages/SettingsPage";
// import Inbox from "./pages/InboxPage";
// import HomePage from "./pages/HomePage";
// import NewHomePage from "./pages/NewHomePage";
// import GamerMatchmakerPage from "./pages/GamerMatchmakerPage";
// import GamePage from "./pages/GamePage";
// import SocialPage from "./pages/SocialPage";
// import VerificationPage from "./pages/VerificationPage";
// import ResetPasswordPage from "./pages/ResetPasswordPage";
// import AskADevQuestionsPage from "./pages/AskADevQuestionsPage";
// import AskIndemniQuestionsPage from "./pages/AskIndemniQuestionsPage";
// import AskAGamerExplorePage from "./pages/AskAGamerExplorePage";
// import AskAGamerListPage from "./pages/AskAGamerListPage";
// import AskAGamerAnswerPage from "./pages/AskAGamerAnswerPage";
// import AskAGamerAskerPage from "./pages/AskAGamerAskerPage";
// import AccountPage from "./pages/AccountPage";
// import VisitAccountPage from "./pages/VisitAccountPage";
// import UniversityPage from "./pages/UniversityPage";
// import LeaderboardPage from "./pages/LeaderboardPage";
// import NewsPage from "./pages/NewsPage";
// import DemoPage from "./pages/DemoPage";
// import AskAGamerWaitlistPage from "./pages/AskAGamerWaitlistPage";

// Components
import Layout from "./components/Layout/Layout";

// Bootstrap Sass (CSS)
import "./index.scss";

// Import the initialisation functions from the Firebase SDKs we need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, TwitterAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Import Mixpanel for analytics
import mixpanel from "mixpanel-browser";

// Initialize Mixpanel
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

// Initialise Firebase
const fbsConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};
const firebase = initializeApp(fbsConfig);
const fbsAnalytics = getAnalytics(firebase);
const fbsAuth = getAuth(firebase);
const fbsAuthGoogle = new GoogleAuthProvider();
const fbsAuthTwitter = new TwitterAuthProvider();
const fbsFirestore = getFirestore(firebase);
const fbsStorage = getStorage(firebase);

// async IIFE for "top-level" await
(async function () {

    // Render DOM
    const root = ReactDOM.createRoot(document.getElementById("root"));
    root.render(
        <React.StrictMode>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={
                                   <IndemniIOSLandingPage
                                       firebase={firebase}
                                       fbsAnalytics={fbsAnalytics}
                                       fbsAuth={fbsAuth}
                                       fbsAuthGoogle={fbsAuthGoogle}
                                       fbsAuthTwitter={fbsAuthTwitter}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}
                                   />
                               }
                        />
                        <Route path="about" element={
                                   <IndemniIOSAboutPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}
                                   />
                               }
                        />
                        {/* Old Home page */}
                        {/* <Route path="home" element={
                                   <HomePage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}

                                       moralis={Moralis}
                                       evmChain={EvmChain}
                                       walletModal={defaultWalletModal}
                                   />
                               }
                        /> */}
                        {/* <Route path="home" element={
                                   <NewHomePage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}
                                   />
                               }
                        /> */}
                        {/* <Route path="gamermatchmaker" element={
                                   <GamerMatchmakerPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}
                                   />
                               }
                        /> */}
                        {/* <Route path="demo" element={
                                   <DemoPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}
                                   />
                               }
                        /> */}
                        {/* <Route path="waitlist" element={
                                   <AskAGamerWaitlistPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}
                                   />
                               }
                        /> */}
                        {/* <Route path="game" element={
                                   <GamePage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}
                                   />
                               }
                        />
                        <Route path="gamerlounge" element={
                                   <SocialPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}
                                   />
                               }
                        /> */}
                        {/* The dynamic url for visiting/non-loggedin user - prefered this over
                            urlsearchparams - allows us to create a version of newhome that only
                            shows certain readable info
                         */
                        }
                        {/* <Route path="askindemni" element={
                                   <AskIndemniExplorePage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}
                                   />
                               }
                        />
                        <Route path="askadev" element={
                                   <AskADevExplorePage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                   />
                               }
                        />
                        <Route path="askagamer" element={
                                   <AskAGamerExplorePage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                   />
                               }
                        />
                        <Route path="askadevquestions" element={
                                   <AskADevQuestionsPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                   />
                               }
                        />
                        <Route path="askindemniquestions" element={
                                   <AskIndemniQuestionsPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}
                                   />
                               }
                        />
                        <Route path="askagamerlist" element={
                                   <AskAGamerListPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}
                                       firebase={firebase}
                                   />
                               }
                        />
                        <Route path="askagameranswer" element={
                                   <AskAGamerAnswerPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}
                                       firebase={firebase}
                                   />
                               }
                        /> */}
                        {/* <Route path="account" element={
                                   <AccountPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}
                                       firebase={firebase}
                                   />
                               }
                        />
                        <Route path="visitaccount" element={
                                   <VisitAccountPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}
                                       firebase={firebase}
                                   />
                               }
                        />
                        <Route path="myuniversity" element={
                                   <UniversityPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}
                                       firebase={firebase}
                                   />
                               }
                        />
                        <Route path="leaderboards" element={
                                   <LeaderboardPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}
                                       firebase={firebase}
                                   />
                               }
                        />
                        <Route path="news" element={
                                   <NewsPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}
                                       firebase={firebase}
                                   />
                               }
                        /> */}
                        {/* <Route path="askagamerasker" element={
                                   <AskAGamerAskerPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}
                                       firebase={firebase}
                                   />
                               }
                        />
                        <Route path="inbox" element={
                                     <Inbox
                                        fbsAuth={fbsAuth}
                                        fbsFirestore={fbsFirestore}
                                        fbsStorage={fbsStorage}

                                        walletModal={defaultWalletModal}
                                     />
                                 }
                          /> */}
                        {/* <Route path="faq" element={
                                   <FAQPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                   />
                               }
                        />
                        <Route path="verification" element={
                                   <VerificationPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                   />
                               }
                        />
                        <Route path="resetpassword" element={
                                   <ResetPasswordPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                   />
                               }
                        />
                        <Route path="settings" element={
                                   <SettingsPage
                                       fbsAuth={fbsAuth}
                                       fbsAuthGoogle={fbsAuthGoogle}
                                       fbsAuthTwitter={fbsAuthTwitter}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}

                                       web3={window.web3}
                                       moralis={Moralis}
                                       evmChain={EvmChain}
                                       walletModal={defaultWalletModal}
                                   />
                               }
                        /> */}
                        <Route path="*" element={
                                   <NoPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                   />
                               }
                        />
                    </Route>
                </Routes>
            </BrowserRouter>
        </React.StrictMode>
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
})()

