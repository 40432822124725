// The Waitlist Landing Page for Ask A Gamer
// We shouldn't be updating this going forward and it will be removed when we are live
// Used to get Waitlist Sign-ups, and convert people to the demo page.
import React, { Component } from "react"
import { Button, Image, Col, Row, Form, InputGroup, Carousel } from "react-bootstrap"
import { onAuthStateChanged, signOut } from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  query,
  collectionGroup,
  where,
  onSnapshot,
  arrayUnion
} from "firebase/firestore";
import BasicNavbar from "../components/Navbar/Navbar"
import Footerfixed from "../components/Footer/Footer";
import { Discord, Twitter, Youtube, Instagram } from "react-bootstrap-icons";
import { saveAs } from 'file-saver';

class IndemniIOSLandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: undefined,
      userdata: undefined,

      messages: [], // Existing messages
      unreadCount: 0, // Tracks the unread message count

      submitCooldown: false, // prevents button spam and allows submitForm() to complete.
      validated: false, // set to true on waitlist submit - triggers Form feedback.
      alreadyJoined: false, // set to true if the user has already joined the waitlist before based on the email input.
      completedForm: false, // set to true once the user has completed the form and submitForm() completes with required inputs.
      contactMe: false,
    }

    this.logout = this.logout.bind(this)
    this.setUser = this.setUser.bind(this)

    this.setUpMessageListener = this.setUpMessageListener.bind(this)

    this.submitForm = this.submitForm.bind(this)
    this.fetchAndCreateCSV = this.fetchAndCreateCSV.bind(this)

    onAuthStateChanged(this.props.fbsAuth, async user => {
      if (user) {
        await this.setUser(user)
          .then(async () => {
            // This is downloads the waitlist firebase docs to a csv compatabile with google contacts.
            // Update the docId to access different docs.
            // Make sure to only uncomment and run on localhost.
            // await this.fetchAndCreateCSV();
          })
        await this.setUpMessageListener();
      }
      else this.logout();
    })
  }


  // Unsubscribes from message listener.
  componentWillUnmount() {
    if (this.unsubscribeMessageListener)
      this.unsubscribeMessageListener();
  }

  // Listen to changes in the Firestore collection where messages are
  // Snapshots Inbox, then filters out messages that the current user sent to not be displayed,
  // ... then we save filteredOutMessages to be pushed back in markMessageAsRead so the array is updated properly in Firestore,
  // ... then we sort by newest message on top, check for amount of unread messages, and finally set states to track these variables.
  setUpMessageListener() {
    const currentUser = this.state.readonlyUserdata.username;
    const InboxDocRef = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicHiddenPrivateWritable", "0", "Inbox", "0");
    const unsubscribe = onSnapshot(InboxDocRef, (doc) => {
      if (doc.exists()) {
        const messages = doc.data().messages || [];
        // Applies the filter to messages where toName matches the current user's name
        const userMessages = messages.filter((message) => message.toName === currentUser);
        // Saves filtered out messages in state to be updated in markMessageAsRead
        const filteredOutMessages = messages.filter((message) => message.toName !== currentUser);
        this.setState({ filteredOutMessages });
        userMessages.sort((a, b) => b.timeSent - a.timeSent);
        // Counts the number of unread messages.
        const unreadCount = userMessages.filter((message) => !message.read).length;
        // Checks privacy settings & removes unreadCount if the user has any
        if (doc.data().receiveMessages === false) {
          this.setState({ unreadCount: null, messages: userMessages, hideMessages: true })
        }
        // If no privacy settings - sets default unreadCount
        else {
          this.setState({ unreadCount, messages: userMessages })
        }
        console.log("Sorted Message Data:", userMessages);
      }
    });
    this.unsubscribeMessageListener = unsubscribe;
  }

  logout() {
    /* If not logged in, don't try to log out. */
    if (this.state.user === undefined) return;

    signOut(this.props.fbsAuth)
      .then(() => {
        this.setState({
          user: undefined,
          readonlyUserdata: undefined,
          writableUserdata: undefined,
          hiddenWritableUserdata: undefined
        }, () => {
          console.log("Logged out!")
        })
      })
      .catch((error) => {
        // TODO: What do we do if signing out fails?
        console.error("Could not sign out!")
        console.error(`FIREBASEAUTH (${error.code}): ${error.message}`)
        console.error(error)
      })
  }

  async setUser(newUser) {
    return new Promise(async resolve => {
      // Somewhat validate new user
      if (typeof newUser !== "object" || typeof newUser.uid === "undefined") resolve(false);

      const userReadonlyQuery = query(
        collectionGroup(this.props.fbsFirestore, "Readonly"),
        where("uid", "==", newUser.uid)
      );

      const userReadonlySnapshots = await getDocs(userReadonlyQuery);
      if (userReadonlySnapshots.empty) {
        console.error(`Login failed: Could not find user in database with uid == ${newUser.uid}`)
        resolve(false)
        return;
      }

      const userReadonlySnapshot = userReadonlySnapshots.docs[0]
      if (!await userReadonlySnapshot.exists()) {
        console.error(`Login failed: Could not find user in database with uid == ${newUser.uid}`)
        resolve(false)
        return;
      }

      const userReadonlyData = userReadonlySnapshot.data()

      const userNumber = userReadonlyData.userNumber
      const userNumberString = `${userNumber}`

      const userWritableSnapshot = await getDoc(doc(this.props.fbsFirestore, "Users", userNumberString, "PublicReadonlyPrivateWritable", "0"));
      const userWritableData = userWritableSnapshot.data();

      const userHiddenSnapshot = await getDoc(doc(this.props.fbsFirestore, "Users", userNumberString, "PublicHiddenPrivateWritable", "0", "Inbox", "0"));
      const userHiddenData = userHiddenSnapshot.data();

      this.setState({
        user: newUser,                          // Firebase Authentication user object
        readonlyUserdata: userReadonlyData,     // /Users/<userNumber>/Readonly/0
        writableUserdata: userWritableData,     // /Users/<userNumber>/PublicReadonlyPrivateWritable/0
        hiddenWritableUserdata: userHiddenData, // /Users/<userNumber>/PublicHiddenPrivateWritable/0
      }, () => {
        resolve(true)
      })
    })
  }

  // Called after setUesr in onAuthStateChanged() to download waitlist doc information
  // from firebase and converts it a csv to be upload to google contacts.
  // Make sure to comment out either the function call in onAuthStateChanged() or
  // this function all together as well as the bind in the constructor.
  async fetchAndCreateCSV() {
    // "Waitlist", "Waitlist2", "Waitlist3"; // Current Waitlist doc names within the "AskAGamerWaitlist" collection
    const docId = "Waitlist3"; // Change this name to the doc you want to download.
    const waitlistDocRef = doc(this.props.fbsFirestore, "AskAGamerWaitlist", docId);
    const waitlistDocSnapshot = await getDoc(waitlistDocRef);

    if (waitlistDocSnapshot.exists()) {
      const data = waitlistDocSnapshot.data();
      console.log('Fetched Data:', data); // Log the fetched data

      const csvData = [];
      const headers = [
        'Name',
        'Given Name',
        'Family Name',
        'E-mail 1 - Value',
        'Notes',
        'Group Membership'
      ];
      csvData.push(headers.join(','));

      // const users = data.users || []; // Adjust this to the correct key if needed
      // Use the const below instead (and comment out the line above) if you dont want the
      // entire doc, but only the  the most recent contacts. Change the # in slice to
      // object number you want to start from.
      const users = (data.users || []).slice(393);
      users.forEach(entry => {
        console.log('Processing Entry:', entry); // Log each entry being processed

        const row = [
          `"${(entry.first || '') + ' ' + (entry.last || '')}"`, // Name with space and quotes
          `"${entry.first || ''}"`, // Given Name
          `"${entry.last || ''}"`, // Family Name
          `"${entry.email || ''}"`, // Email
          `"${(entry.optionalText || '').replace(/"/g, '""')}"`, // Notes with quotes and escape internal quotes
          `"Indemni Waitlist ::: Haven't Sent Email ::: * myContacts${entry.contactMe ? ' ::: ContactMeTrueNew' : ''}"`
        ];
        console.log('Generated Row:', row.join(',')); // Log each generated row
        csvData.push(row.join(','));
      });

      const csvContent = csvData.join('\n');
      console.log('CSV Content:', csvContent); // Log the final CSV content

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, 'contacts.csv');
    } else {
      console.log("No such document!");
    }
  }

  // ** Added new Docs "Waitlist2", "Waitlist3", etc. to help with bloat of data - checks against if a user
  // already signed up breaks a bit, but I don't want the Doc to get full and bounce people.
  // Submits the waitlist data to firebase if the user completed the required inputs
  // and/or the user hasn't already joined the waitlist before.
  async submitForm() {
    this.setState({ submitCooldown: true, validated: true })

    const form = document.getElementById("submitForm");
    if (form.checkValidity() === false) {
      this.setState({ submitCooldown: false });
      return;
    }

    // const waitlistDocRef = doc(this.props.fbsFirestore, "AskAGamerWaitlist", "Waitlist2");
    // const waitlistDocSnapshot = await getDoc(waitlistDocRef);
    // const waitlistData = waitlistDocSnapshot.data();
    // const waitlistUsers = waitlistData.users || [];
    // const alreadyJoined = waitlistUsers.find(user => user.email === this.state.email);

    const waitlistDocs = ["Waitlist", "Waitlist2", "Waitlist3", "Waitlist4"]; // Add document IDs here as docs grow - needs to be done manually right now.
    let alreadyJoined = false;

    for (const docId of waitlistDocs) {
      const waitlistDocRef = doc(this.props.fbsFirestore, "AskAGamerWaitlist", docId);
      const waitlistDocSnapshot = await getDoc(waitlistDocRef);
      const waitlistData = waitlistDocSnapshot.data();
      const waitlistUsers = waitlistData.users || [];

      if (waitlistUsers.some(user => user.email === this.state.email)) {
        alreadyJoined = true;
        break;
      }
    }

    if (alreadyJoined) {
      this.setState({
        submitCooldown: false,
        alreadyJoined: true,
        completedForm: true,
      });
      console.log("User has already joined the waitlist.")
      return;
    }

    // If passed the logic checks - add the user to the waitlist in firebase.
    const userInfo = {
      email: this.state.email,
      first: this.state.firstName,
      last: this.state.lastName,
      date: new Date(),
      optionalText: this.state.additionalInfo ? this.state.additionalInfo : "",
      referredBy: this.state.referredBy ? this.state.referredBy : "",
      contactMe: this.state.contactMe ? this.state.contactMe : false,
    }

    // Choose the newest/highest number "Waitlist" doc to update here - currently "Waitlist3"
    const waitlistDocRefToUpdate = doc(this.props.fbsFirestore, "AskAGamerWaitlist", "Waitlist3");
    await updateDoc(waitlistDocRefToUpdate, {
      users: arrayUnion(userInfo)
    });
    this.setState({
      submitCooldown: false,
      completedForm: true,
    })
    console.log("Joined Waitlist!")
  }

  render() {
    return (
      <>
        {/* <BasicNavbar
          user={this.state.user}
          userdata={{ ...this.state.readonlyUserdata, ...this.state.writableUserdata, ...this.state.hiddenWritableUserdata }}
          unreadCount={this.state.unreadCount}
        /> */}
        <div
          className="body-content visting-home-page-fade-animation change-explore-body-content-small-screen explore-body-content-padding-mobile
                    explore-body-content-padding-large ask-a-gamer-explore-padding-left-right-1-mobile bgImageLanding"
        >
          <div className="mt-2 pb-5" >
            <div className="d-flex flex-column justify-content-between align-items-center game-details-flex-column-mobile pb-3">
              <h4
                className="p-1 mb-2 d-flex flex-column text-center change-waitlist-description-style-desktop"
                style={{ "width": "350px", "margin-top": "-1.3em", "margin-bottom": "0em" }}
              >
                <div style={{ "position": "relative", "display": "inline-block", "overflow": "hidden", "padding": "1em" }}>
                  <div className="indemniIOS-title-Bg" />
                  <span
                    style={{ "font-size": "2em" }}
                    className="mb-2 roadRageFont-white indemniIOS-title-text"
                  >
                    {'Inde>mni'}
                  </span>
                </div>
                <div className="me-2 px-2 pb-3 ask-a-gamer-explore-margin-padding-right-left-0-mobile">
                  <div className="me-2 px-2 explore-games-background ask-a-gamer-explore-margin-right-0-mobile">
                    <div
                      className="d-flex flex-column py-3 px-4 ask-a-gamer-game-list-border ask-a-gamer-explore-padding-left-right-halfem-mobile"
                      style={{ "background-color": "#fff", "z-index": "2" }}
                    >
                      <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div
                          className="d-inline-flex flex-column align-items-center flex-wrap ask-a-gamer-explore-flex-column-mobile"
                          style={{ "width": "100%" }}
                        >
                          <span
                            style={{ "font-size": ".9em", "width": "100%" }}
                            className="roadRageFont-pink"
                          >
                            The Social App You Experience as a Video Game
                          </span>
                          <span
                            style={{ "font-size": ".9em", "width": "100%", "text-decoration": "underline" }}
                            className="barlow700Font-black mt-2"
                          >
                            Coming Soon to iOS & Android!
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </h4>
              <h4
                className="p-1 mb-3 text-center change-waitlist-description-style-desktop"
                style={{ "width": "350px", "margin-left": ".75em", "margin-top": "-1em" }}
              >
                <div
                  className="d-flex flex-row align-items-center justify-content-center p-1"
                >
                  <a
                    href='https://discord.gg/9EZmV5qBY5'
                    target="_blank"
                    rel="noreferrer"
                    className='me-4 footer-text-black'
                  >
                    <Discord style={{ "height": "23px", "width": "auto" }} />
                  </a>
                  <a
                    href='https://www.youtube.com/@Indemnigaming'
                    target="_blank"
                    rel="noreferrer"
                    className='me-4 footer-text-black'
                  >
                    <Youtube style={{ "height": "23px", "width": "auto" }} />
                  </a>
                  <a
                    href='https://twitter.com/Indemnigaming'
                    target="_blank"
                    rel="noreferrer"
                    className='me-4 footer-text-black'
                  >
                    <Twitter style={{ "height": "20px", "width": "auto" }} />
                  </a>
                  <a
                    href='https://www.instagram.com/indemnigaming'
                    target="_blank"
                    rel="noreferrer"
                    className='me-4 footer-text-black'
                  >
                    <Instagram style={{ "height": "19px", "width": "auto" }} />
                  </a>
                </div>
              </h4>
              {/* <div className="me-2 px-2 py-3 ask-a-gamer-explore-margin-padding-right-left-0-mobile">
                <div className="me-2 px-2 explore-games-background ask-a-gamer-explore-margin-right-0-mobile">
                  <div className="d-flex flex-column py-3 px-4 ask-a-gamer-game-list-border ask-a-gamer-explore-padding-left-right-halfem-mobile">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                      <div
                        className="d-inline-flex flex-row align-items-center flex-wrap ask-a-gamer-explore-flex-column-mobile"
                        style={{ "width": "100%" }}
                      >
                        {!this.state.completedForm
                          ? (
                            <>
                              <h1
                                style={{ "font-weight": "700", "width": "100%", "color": "#f9ff7e" }}
                                className="my-3 center-and-flex-important flex-column pb-2 text-center"
                              >
                                Waitlist
                                <br />
                                <span style={{ "font-size": ".8em", "color": "#ffff" }}>
                                  Guaranteed Early Access
                                  <br />
                                  Win a PS5 and Much More!
                                </span>
                                <span style={{ "font-size": ".6em", "margin-top": ".5em", "font-weight": "600" }}>
                                  ( Takes 15 Seconds - Almost Full ! )
                                </span>
                              </h1>
                            </>
                          )
                          : (
                            !this.state.alreadyJoined
                              ? (
                                <h1
                                  style={{ "font-weight": "700", "width": "100%", "color": "#f9ff7e" }}
                                  className="my-3 pb-2 text-center"
                                >
                                  Joined!
                                </h1>
                              )
                              : (
                                <h1
                                  style={{ "font-weight": "700", "width": "100%", "color": "#ff5858f0" }}
                                  className="my-3 pb-2 text-center"
                                >
                                  Already Joined!
                                </h1>
                              )
                          )
                        }
                        <div
                          style={{ "width": "100%" }}
                          className="d-flex flex-column align-items-center justify-content-center p-3 glow ask-a-gamer-explore-margin-right-left-0-tablet"
                        >
                          {!this.state.completedForm
                            ?
                            <span style={{ "font-size": "1.1em", "text-align": "center", "font-weight": "700", "margin-bottom": "1em" }}>
                              We will not share any of your personal information or spam you ever!
                            </span>
                            :
                            <span style={{ "font-size": "1.1em", "text-align": "center", "font-weight": "700", "margin-bottom": "1em" }}>
                              Join our <a href="https://discord.gg/9EZmV5qBY5" target="_blank" className="change-link-color-chat">Discord</a>  to learn more, meet the community, stay up to date, and chat with us. See you soon!
                            </span>
                          }
                          {!this.state.completedForm
                            ?
                            <Form
                              style={{ "width": "100%" }}
                              id="submitForm"
                              noValidate
                              validated={this.state.validated}
                            >
                              <Row className="mb-3">
                                <Form.Group as={Col} md="6" controlId="firstName" className="mb-2">
                                  <Form.Label style={{ "font-size": "1.2em", "font-weight": "600" }}>
                                    First name
                                  </Form.Label>
                                  <Form.Control
                                    value={this.state.firstName}
                                    onChange={(e) => {
                                      const firstNameInput = e.target.value;
                                      this.setState({
                                        firstName: firstNameInput
                                      });
                                    }}
                                    required
                                    type="text"
                                    placeholder="Your First Name"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please provide a valid name.
                                  </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="lastName" className="mb-2">
                                  <Form.Label style={{ "font-size": "1.2em", "font-weight": "600" }}>
                                    Last name
                                  </Form.Label>
                                  <Form.Control
                                    value={this.state.lastName}
                                    onChange={(e) => {
                                      const lastNameInput = e.target.value;
                                      this.setState({
                                        lastName: lastNameInput
                                      });
                                    }}
                                    required
                                    type="text"
                                    placeholder="Your Last Name"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please provide a valid name.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Row>
                              <Row className="mb-3">
                                <Form.Group as={Col} md="6" controlId="email" className="mb-3">
                                  <Form.Label style={{ "font-size": "1.2em", "font-weight": "600" }}>
                                    Email
                                  </Form.Label>
                                  <Form.Control
                                    value={this.state.email}
                                    onChange={(e) => {
                                      const emailInput = e.target.value;
                                      this.setState({
                                        email: emailInput
                                      });
                                    }}
                                    type="email"
                                    placeholder="Your Email"
                                    required
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please provide a valid email.
                                  </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="additionalInfo" className="mb-2">
                                  <Form.Label style={{ "font-size": "1.2em", "font-weight": "600" }}>
                                    Additional Info / Your Social Username (optional)
                                  </Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    value={this.state.additionalInfo}
                                    onChange={(e) => {
                                      const additionalInfoInput = e.target.value;
                                      this.setState({
                                        additionalInfo: additionalInfoInput
                                      });
                                    }}
                                    type="text"
                                    style={{ "height": "110px" }}
                                    maxLength={300}
                                    placeholder="Feel free to tell us your username for your Socials and/or give us more info, feedback, and ask any follow-up questions you'd like to know! (300 character limit)"
                                    optional
                                  />
                                  <Form.Control.Feedback>
                                    If you added additional info - Thanks! We will get back to you soon.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Row>
                              <Row className="mb-3 align-items-center justify-content-center">
                                <Form.Group as={Col} md className="mb-3" style={{ "margin-top": "1em", "font-size": "1.1em" }} controlId="contactMe">
                                  <Form.Check
                                    type="checkbox"
                                    // checked={this.state.contactMe}
                                    onChange={(e) => {
                                      this.setState({
                                        contactMe: e.target.checked
                                      });
                                    }}
                                    label="I want to be contacted for feedback with MrIndemni (optional)."
                                    optional
                                  />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="additionalInfo" className="mb-2">
                                  <Form.Label style={{ "font-size": "1.2em", "font-weight": "600" }}>
                                    Were You Referred? (optional)
                                  </Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    value={this.state.referredBy}
                                    onChange={(e) => {
                                      const referredByInput = e.target.value;
                                      this.setState({
                                        referredBy: referredByInput
                                      });
                                    }}
                                    type="text"
                                    style={{ "height": "60px" }}
                                    maxLength={100}
                                    placeholder="Put the person's Indemni Username or Email here!"
                                    optional
                                  />
                                  <Form.Control.Feedback>
                                    If you added mentioned who referred you - Thanks! We/They appreciate it!
                                  </Form.Control.Feedback>
                                </Form.Group>
                                <Button
                                  className="mb-2 mx-2 chat-with-gamer-button"
                                  style={{ "width": "150px", "height": "50px", "margin-top": "2em" }}
                                  disabled={this.state.submitCooldown || this.state.completedForm}
                                  onClick={async () => {
                                    await this.submitForm();
                                  }}
                                >
                                  Submit
                                </Button>
                              </Row>
                            </Form>
                            :
                            <div
                              style={{ "width": "100%" }}
                              className="center-and-flex-important flex-column"
                            >
                              {!this.state.alreadyJoined
                                ?
                                <h4
                                  style={{ "width": "100%", "font-size": "1.25em" }}
                                  className="text-center"
                                >
                                  Thanks for joining the waitlist! Seriously, it means so much to us - welcome to the Indemni family.
                                  <br />
                                  <br />
                                  Make sure to try the <a href="/demo" target="_blank" className="change-link-color-chat">Demo</a> to experience our matchmaking system if you haven't yet! If you think this cool, please share the platform with a friend or on socials for even more awesome rewards (details on the Share/Referral Giveaways are below).
                                  <br />
                                  <br />
                                  We will be launching the platform and announcing the winner of the giveaway on the site on June 11th. We will send you a follow-up email within the next 2 days with more important information.
                                </h4>
                                :
                                <h4
                                  style={{ "width": "100%", "font-size": "1.25em" }}
                                  className="text-center"
                                >
                                  You are already on the waitlist! You should have received a follow-up email with more important information (or you definitely will soon!) about the platform, giveaway, and how to access the site in June. Be sure to check your spam folder for the email! Reach out to us if you need additional help!
                                </h4>
                              }
                              <br />
                              <br />
                              <span
                                style={{ "width": "100%", "font-size": "1.2em" }}
                                className="p-3 text-center"
                              >
                                Check out the FAQ for even more in-depth info <a href="/faq" target="_blank" className="change-link-color-chat">Here</a>.
                              </span>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <hr
                style={{ "width": "100%" }}
                className="hr-ask-indemni-title-hr"
              />
              <h4
                className="p-1 mb-3 text-center change-waitlist-description-style-desktop"
                style={{ "width": "350px" }}
              >
                <span
                  style={{ "font-size": "1.3em", "font-weight": "600" }}
                  className="mb-4"
                >
                  Find Your Perfect Teammate Now
                </span>
                <br />
                <div
                  style={{ "width": "100%" }}
                  className="d-flex flex-row align-items-center justify-content-center font-size-75-tablet mt-3"
                >
                  <Button
                    className="d-flex flex-column mx-1 p-1 align-items-center chat-with-gamer-button game-details-play"
                    style={{ "width": "200px", "box-shadow": "#fffzfff82 0px 0px 10px 3px", "font-size": "1em", "font-weight": "550", "text-shadow": "black 0px 2px 2px" }}
                    href="/demo"
                    target="_blank"
                  >
                    Try Demo!
                  </Button>
                </div>
              </h4>
              <hr
                style={{ "width": "100%" }}
                className="hr-ask-indemni-title-hr"
              />
              {this.state.alreadyJoined
                ?
                <>
                  <h4
                    className="p-3 text-center"
                    style={{ "width": "350px" }}
                  >
                    Thank you for joining!
                    <br />
                    <br />
                    As a reminder, we will be launching on June 11th and anouncing the giveaway winners on our site that day. Check your email for important information from us via the email contact@indemni.io! Remember to check out the waitlist/referral perks below!
                  </h4>
                  <h4
                    className="p-3 text-center change-waitlist-description-style-desktop"
                    style={{ "width": "350px", "margin-top": "1em" }}
                  >
                    <span
                      style={{ "color": "#f9ff7e", "font-size": "1.2em" }}
                      className="mb-2"
                    >
                      Waitlist Perks (Giveaways):
                    </span>
                    <br />
                    <br />
                    <span style={{ "font-size": ".8em", "font-style": "normal", "font-weight": "450" }}>
                      <h3 style={{ "font-size": "1em", "margin-top": "-.9em", "margin-bottom": "1em", "text-align": "start" }}>
                        By joining the waitlist you qualify for all the rewards below! Winners will be announced the day we launch early access on June 11th. We will also reach out to you directly if you win.
                      </h3>
                      <ul className="text-start">
                        <li
                          className="py-1"
                          style={{ "color": "#30ff9a", "font-weight": "500" }}
                        >
                          One person: Will have their choice of either a PlayStation 5 or Logitech G715 keyboard and G502 mouse.
                        </li>
                        <li className="py-1">Everyone: Will have guaranteed early-access (*Server is capped at 10K users the first month).</li>
                        <li className="py-1">Everyone: Will have special access to beta features not available to the public: New Promotion/Partnership options, more gaming profile customizations, and more!</li>
                        <li className="py-1">Two people: Will have a feature of their choice developed for the platform (*within reason…) and have their username permanently displayed on the Indemni Landing/Login Page.</li>
                        <li className="py-1">Everyone: Will get a special Indemni Discord Role/Title: “True Believers”</li>
                        <li className="py-1">Twenty people: Will get a unique Indemni t-shirt.</li>
                        <li className="py-1">Everyone: We will love you forever.</li>
                        <li
                          style={{ "color": "#30ff9a", "font-weight": "500" }}
                          className="py-1"
                        >
                          Many More Rewards are Available if You Share! Details Are Below!
                        </li>
                      </ul>
                    </span>
                  </h4>
                </>
                :
                <h4
                  className="p-3 text-center change-waitlist-description-style-desktop"
                  style={{ "width": "350px", "margin-top": "1em" }}
                >
                  <span
                    style={{ "color": "#f9ff7e", "font-size": "1.2em" }}
                    className="mb-2"
                  >
                    Waitlist Perks (Giveaways):
                  </span>
                  <br />
                  <br />
                  <span style={{ "font-size": ".8em", "font-style": "normal", "font-weight": "450" }}>
                    <h3 style={{ "font-size": "1em", "margin-top": "-.9em", "margin-bottom": "1em", "text-align": "start" }}>
                      By joining the waitlist you qualify for all the rewards below! Winners will be announced the day we launch early access on June 11th. We will also reach out to you directly if you win.
                    </h3>
                    <ul className="text-start">
                      <li
                        className="py-1"
                        style={{ "color": "#30ff9a", "font-weight": "500" }}
                      >
                        One person: Will have their choice of either a PlayStation 5 or Logitech G715 keyboard and G502 mouse.
                      </li>
                      <li className="py-1">Everyone: Will have guaranteed early-access (*Server is capped at 10K users the first month).</li>
                      <li className="py-1">Everyone: Will have special access to beta features not available to the public: New Promotion/Partnership options, more gaming profile customizations, and more!</li>
                      <li className="py-1">Two people: Will have a feature of their choice developed for the platform (*within reason…) and have their username permanently displayed on the Indemni Landing/Login Page.</li>
                      <li className="py-1">Everyone: Will get a special Indemni Discord Role/Title: “True Believers”</li>
                      <li className="py-1">Twenty people: Will get a unique Indemni t-shirt.</li>
                      <li className="py-1">Everyone: We will love you forever.</li>
                      <li
                        style={{ "color": "#30ff9a", "font-weight": "500" }}
                        className="py-1"
                      >
                        Many More Rewards are Available if You Share! Details Are Below!
                      </li>
                    </ul>
                  </span>
                </h4>
              } */}
            </div>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ marginTop: '-3em' }}
            >
              <div className="p-2 mt-3 mb-3 game-details-play" style={{ maxWidth: '920px' }}>
                <video
                  src="/VerticalIntro_v1.mp4"            // Internal mp4 video from your /assets folder
                  autoPlay={true}                         // Starts playing when rendered
                  controls                           // Shows the controls (optional)
                  playsInline                        // Good for mobile playback
                  style={{ width: '100%', height: '650px' }}
                  muted={true}
                  loop={true}
                >
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
            <div className="d-flex flex-column justify-content-between align-items-center game-details-flex-column-mobile ">
              <h4
                className="p-1 d-flex flex-column text-center change-waitlist-description-style-desktop"
                style={{ "width": "350px", "margin-top": "-.5em", "margin-bottom": "0em" }}
              >
                <div className="me-2 px-2 pb-3 ask-a-gamer-explore-margin-padding-right-left-0-mobile">
                  <div className="me-2 px-2 explore-games-background ask-a-gamer-explore-margin-right-0-mobile">
                    <div
                      className="d-flex flex-column py-3 px-4 ask-a-gamer-game-list-border ask-a-gamer-explore-padding-left-right-halfem-mobile"
                      style={{ "background-color": "#fff", "z-index": "2" }}
                    >
                      <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div
                          className="d-inline-flex flex-column align-items-center flex-wrap ask-a-gamer-explore-flex-column-mobile"
                          style={{ "width": "100%" }}
                        >
                          <span
                            style={{ "font-size": ".9em", "width": "100%" }}
                            className="interFont-black"
                          >
                            Visit our Socials to see live app/gameplay footage, to join the community, and to get access to the upcoming Closed Beta.
                          </span>
                          <span
                            style={{ "font-size": ".9em", "width": "100%" }}
                            className="interFont-black mt-2"
                          >
                            Or, read this stuff &rarr; <a href='/about'>About Indemni</a>
                          </span>
                          <span
                            style={{ "font-size": "1em", "width": "100%", "stroke": "black" }}
                            className="roadRageFont-pink mt-3"
                          >
                            Btw ... check out some screenshots below!
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center w-100">
                  <div className="chevron"></div>
                  <div className="chevron"></div>
                  <div className="chevron"></div>
                </div>
                <h1
                  style={{ "font-weight": "700", "width": "100%", "margin-top": "5.5em" }}
                  className="pb-2 barlow700Font-black d-flex flex-column justify-content-between align-items-center"
                >
                  In-App Screenshots
                  <span
                    style={{ "font-weight": "700", "font-size": ".8em", "width": "100%" }}
                    className="pb-2 barlow700Font-black"
                  >
                    (Captured on an iPhone 15)
                  </span>
                </h1>
              </h4>
            </div>

            {/* Second Video: Landscape and responsive, no auto play */}
            {/* <div className="d-flex mt-3 justify-content-center align-items-center">
              <div className="p-2 mt-3 mb-3 game-details-play" style={{ maxWidth: '920px' }}>
                <div className="embed-responsive embed-responsive-16by9">
                  <video
                    src="/assets/video2.mp4"         // Your second internal mp4 file
                    controls                         // Shows controls so the user can play manually
                    playsInline                      // Ensures proper mobile behavior
                    className="embed-responsive-item"
                    style={{ width: '100%', height: '100%' }}
                  >
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div> */}

            <div className="d-flex justify-content-center align-items-center">
              <div className="p-2 mb-3 game-details-play" style={{ maxWidth: '920px', marginTop: '-.5em' }}>
                <Carousel fade>
                  <Carousel.Item>
                    <Image
                      className="shadow"
                      src="/IndemniCity_ScreenShot.PNG"
                      alt="Indemni City"
                      rounded
                      fluid
                      text="Indemni City"
                    />
                    <Carousel.Caption className="carouselCaptionOverride">
                      <h6>Your New Home</h6>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Image
                      className="shadow"
                      src="/EarthCity_FightStart_ScreenShot.PNG"
                      alt="Hero Fighting Micro Transactions"
                      rounded
                      fluid
                      text="Fighting Micro T."
                    />
                    <Carousel.Caption className="carouselCaptionOverride">
                      <h6>Stop the Villian!</h6>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Image
                      className="shadow"
                      src="/ArcadexWideView_ScreenShot.PNG"
                      alt="Arcadex Entry"
                      rounded
                      fluid
                      text="Arcadex"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <Image
                      className="shadow"
                      src="/ArcadexOverwatchPlayers_ScreenShot.PNG"
                      alt="Arcadex Overwatch Players"
                      rounded
                      fluid
                      text="Join up and play anything with the community."
                    />
                    <Carousel.Caption className="carouselCaptionOverride">
                      <h6>Meet/Play with the Community</h6>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Image
                      className="shadow"
                      src="/IndemniCity_Rooftop_ScreenShot.PNG"
                      alt="The Rooftop"
                      rounded
                      fluid
                      text="The Rooftop"
                    />
                    <Carousel.Caption className="carouselCaptionOverride">
                      <h6>The Rooftop</h6>
                      <p
                        style={{ "margin-bottom": "0em", "margin-top": "-.75em", "font-size": ".65em" }}
                      >
                        The hangout spot for players.
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Image
                      className="shadow"
                      src="/MeetYourSelf_ScreenShot.PNG"
                      alt="Meet Yourself"
                      rounded
                      fluid
                      text="Be Reborn as a Hero."
                    />
                    <Carousel.Caption className="carouselCaptionOverride">
                      <h6>Be Reborn as a Hero</h6>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Image
                      className="shadow"
                      src="/PlayerBook_ScreenShot.PNG"
                      alt="The Initiation - Choose Your Story."
                      rounded
                      fluid
                      text="Choose Your Story."
                    />
                    <Carousel.Caption className="carouselCaptionOverride">
                      <h6>Choose Your Story</h6>
                      <p
                        style={{ "margin-bottom": "0em", "margin-top": "-.75em", "font-size": ".65em" }}
                      >
                        Your experience is tailored to who you are.
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Image
                      className="shadow"
                      src="/TheVoid_ActionSelect_ScreenShot.PNG"
                      alt="The Void"
                      rounded
                      fluid
                      text="The Void"
                    />
                    <Carousel.Caption className="carouselCaptionOverride">
                      <h6>The Void</h6>
                      {/* <p
                        style={{ "margin-bottom": "0em", "margin-top": "-.75em" }}
                      >
                        Everything you say/do in Indemni is a reflection of who you are and alters your experience.
                      </p> */}
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Image
                      className="shadow"
                      src="/OvalWarning_ScreenShot.PNG"
                      alt="The Oval"
                      rounded
                      fluid
                      text="A New Threat Emerges"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <Image
                      className="shadow"
                      src="/Dojo_Ascension_ScreenShot.PNG"
                      alt="Ascension"
                      rounded
                      fluid
                      text="Ascension"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <Image
                      className="shadow"
                      src="/Dojo_SecondaryAbilityPreview_ScreenShot.PNG"
                      alt="Choose Your Abilities"
                      rounded
                      fluid
                      text="Your Occupation & Passions Are Your Abilities."
                    />
                    <Carousel.Caption className="carouselCaptionOverride">
                      {/* <h6>Your Occupation & Passions Are Your Abilities</h6> */}
                      <p
                        style={{ "margin-bottom": "0em", "margin-top": "-.75em", "font-size": ".65em" }}
                      >
                        Your Occupation & Passions Are Your Abilities
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Image
                      className="shadow"
                      src="/MrIndemniOffice_ScreenShot.PNG"
                      alt="MrIndemni's Office"
                      rounded
                      fluid
                      text="Join the family and be celebrated for who you truly are."
                    />
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
            {/* <div
              className="my-2 pe-3 w-100 ask-a-gamer-explore-margin-padding-right-left-0-mobile"
              style={{ "height": "95%" }}
            >
              <div className="h-100 w-95 p-3 ask-a-gamer-explore-margin-padding-right-left-0-mobile">
                <div className="me-2 px-2 py-3 ask-a-gamer-explore-margin-padding-right-left-0-mobile">
                  <div className="me-2 px-2 explore-games-background ask-a-gamer-explore-margin-right-0-mobile" >
                    <div className="d-flex flex-column py-3 px-4 ask-a-gamer-game-list-border ask-a-gamer-explore-padding-left-right-halfem-mobile">
                      <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div
                          className="d-inline-flex flex-row align-items-center flex-wrap ask-a-gamer-explore-flex-column-mobile"
                          style={{ "width": "100%" }}
                        >
                          <h1
                            style={{ "font-weight": "700", "width": "100%", "color": "#f9ff7e" }}
                            className="my-3 pb-2 text-center"
                          >
                            New Gamer, Wut Dis? (About Indemni)
                          </h1>
                          <div
                            style={{ "width": "100%" }}
                            className="d-flex flex-column align-items-center justify-content-center p-3 glow ask-a-gamer-explore-margin-right-left-0-tablet"
                          >
                            <span className="p-1" style={{ "font-size": "1.15em", "font-style": "normal", "color": "#ffffff", "font-weight": "500" }}>
                              Indemni is a platform to find other people to play games with. Another way to think about it is as an LFG and Party-Finder for all games and gaming activities. Except, Indemni is much more than this - we are a true gaming community. This is a place where you can find friends and people looking to game the same way you do. Whether you want to pick up 1 person to fill your Fortnite team, or you’re looking for someone to help you improve at League of Legends, or trying to find someone that goes to your college and that mains Tank in World of Warcraft and can raid Sundays from 10am to 4pm EST … the gamer you are looking for is here waiting for you.
                              <br />
                              <br />
                              When you log on to Indemni you have two choices -  look for someone to game with by searching the directory or  you can “Start Gaming,” which puts your profile in the directory so that people can find you. The first option is great if you are browsing casually, are newer, shy, looking for something specific, or just don’t want to create a post. With the second option though, you can really lean into what this platform offers: showcasing who you are as a gamer. This is a bit of a ‘choose your own adventure’ experience. You could simply post what you are specifically looking to do, which is chill. Or … you can create an entire gamer profile showing off your gaming achievements, where/when you game, promoting your socials/content, sell coaching services, talk about what games you love, etc. The freedom here means you get to decide what Indemni is for you:
                              <ul className="text-start">
                                <li className="py-1">Want to find a rando to play Madden and FIFA with who likes fart jokes? Customize your profile and post to find that person.</li>
                                <li className="py-1">Want to build a streaming career? Help users, stream it, and/or promote.</li>
                                <li className="py-1">Already are a streamer or pro? Sell coaching services, or play and chat with users while using Indemni as another platform for your content.</li>
                                <li className="py-1">Want to become a bad a** mf’er who everyone knows on Indemni? Help other gamers out, make friends, and climb the Indemni Leaderboards.</li>
                                <li className="py-1">Want to make some cash? Beyond being able to promote your services, we encourage and incentivize a culture of tipping.</li>
                                <li className="py-1">Want to find best friends? Same, do you know any!? JK we totally have talked to other humans before … But seriously, you can tailor your profile to show off who you are and what you like to do and find people just as passionate about your preferred gaming experience as you are!</li>
                              </ul>
                              <br />
                              We realize that this is a broad description, but we didn’t want to put a cap on what Indemni was for our users. We just wanted to create a platform and environment where gamers could be themselves, pursue their gaming goals, and meet other people. We want YOU to tell us what we should build next, what we should optimize, and how we can create a platform that you love. We will be annoyingly open with our decisions, always ask for feedback, and try to talk with each and everyone that joins Indemni. This is a journey towards making something/being a part of something special.
                              <br />
                              <br />
                              Come join the Indemni family where you can be whoever and game however the heck you want.
                              <br />
                              <br />
                              *P.S. Thank you everyone for the support. Our DMs, Discord, and emails are all open if you want to reach out to chat about anything!
                              <br />
                            </span>
                            <div className="center-and-flex-important ask-indemni-reduce-font-size-mobile px-2 ask-a-gamer-explore-flex-column-page-input-mobile">
                              <span
                                className="my-2"
                                style={{ "font-size": "1.4em", "font-weight": "600", "padding-bottom": "1em", "margin-top": "-.5em", "color": "#3dff57" }}
                              >
                                Interested in more info ? See the <a href="/faq" target="_blank" className="change-link-color-chat">FAQ</a>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="hr-ask-indemni-title-hr" />
                <div className="me-2 px-2 py-3 ask-a-gamer-explore-margin-padding-right-left-0-mobile">
                  <div className="me-2 px-2 explore-games-background ask-a-gamer-explore-margin-right-0-mobile" >
                    <div className="d-flex flex-column py-3 px-4 ask-a-gamer-game-list-border ask-a-gamer-explore-padding-left-right-halfem-mobile">
                      <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div
                          className="d-inline-flex flex-row align-items-center flex-wrap ask-a-gamer-explore-flex-column-mobile"
                          style={{ "width": "100%" }}
                        >
                          <h4
                            style={{ "font-weight": "700", "width": "100%", "color": "#f9ff7e" }}
                            className="my-3 pb-2 text-center"
                          >
                            Thanks For Reading This Far! Remember to sign up for the waitlist and try out our <a href="/demo" target="_blank" className="change-link-color-chat">Demo</a>!
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          {/* Footer for mobile, since footer fixed gets buggy on mobile. */}
          {/* <footer className="hide-mobile-footer">
            <div
              style={{ "margin-left": "3em" }}
              className="d-flex flex-row align-items-center justify-content-center p-1"
            >
              <a
                href='https://discord.gg/9EZmV5qBY5'
                target="_blank"
                className='me-4 text-reset'
              >
                <Discord style={{ "height": "23px", "width": "auto" }} />
              </a>
              <a
                href='https://www.youtube.com/@Indemnigaming'
                target="_blank"
                className='me-4 text-reset'
              >
                <Youtube style={{ "height": "23px", "width": "auto" }} />
              </a>
              <a
                href='https://twitter.com/Indemnigaming'
                target="_blank"
                className='me-4 text-reset'
              >
                <Twitter style={{ "height": "20px", "width": "auto" }} />
              </a>
              <a
                href='https://www.instagram.com/indemnigaming'
                target="_blank"
                className='me-4 text-reset'
              >
                <Instagram style={{ "height": "19px", "width": "auto" }} />
              </a>
            </div>
          </footer> */}
          {/* <Footerfixed></Footerfixed> */}
        </div>
      </>
    )
  }
}

export default IndemniIOSLandingPage;
