// The About page
// TODO: Update with appropriate info - just has legal docs and old code for now lol.
import React, { Component } from "react"
import { onAuthStateChanged, signOut } from "firebase/auth";
import { Discord, Twitter, Youtube, Instagram } from "react-bootstrap-icons";

class IndemniIOSAboutPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: undefined,
      userdata: undefined,

      messages: [], // Existing messages
      unreadCount: 0, // Tracks the unread message count

      submitCooldown: false, // prevents button spam and allows submitForm() to complete.
      validated: false, // set to true on waitlist submit - triggers Form feedback.
      alreadyJoined: false, // set to true if the user has already joined the waitlist before based on the email input.
      completedForm: false, // set to true once the user has completed the form and submitForm() completes with required inputs.
      contactMe: false,
    }
  }

  render() {
    return (
      <>
        {/* <BasicNavbar
          user={this.state.user}
          userdata={{ ...this.state.readonlyUserdata, ...this.state.writableUserdata, ...this.state.hiddenWritableUserdata }}
          unreadCount={this.state.unreadCount}
        /> */}
        <div
          className="body-content visting-home-page-fade-animation change-explore-body-content-small-screen explore-body-content-padding-mobile
                    explore-body-content-padding-large ask-a-gamer-explore-padding-left-right-1-mobile bgImageLanding"
        >
          <div className="mt-2 pb-5" >
            <div className="d-flex flex-column justify-content-between align-items-center game-details-flex-column-mobile pb-3">
              <h4
                className="p-1 mb-2 d-flex flex-column text-center change-waitlist-description-style-desktop"
                style={{ "width": "350px", "margin-top": "-1.3em", "margin-bottom": "0em" }}
              >
                <div style={{ "position": "relative", "display": "inline-block", "overflow": "hidden", "padding": "1em" }}>
                  <div className="indemniIOS-title-Bg" />
                  <span
                    style={{ "font-size": "2em" }}
                    className="mb-2 roadRageFont-white indemniIOS-title-text"
                  >
                    {'Inde>mni'}
                  </span>
                </div>
                <div className="me-2 px-2 pb-3 ask-a-gamer-explore-margin-padding-right-left-0-mobile">
                  <div className="me-2 px-2 explore-games-background ask-a-gamer-explore-margin-right-0-mobile">
                    <div
                      className="d-flex flex-column py-3 px-4 ask-a-gamer-game-list-border ask-a-gamer-explore-padding-left-right-halfem-mobile"
                      style={{ "background-color": "#fff", "z-index": "2" }}
                    >
                      <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div
                          className="d-inline-flex flex-column align-items-center flex-wrap ask-a-gamer-explore-flex-column-mobile"
                          style={{ "width": "100%" }}
                        >
                          <span
                            style={{ "font-size": ".9em", "width": "100%" }}
                            className="roadRageFont-pink"
                          >
                            The Social App You Experience as a Video Game
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </h4>
              <h4
                className="p-1 mb-3 text-center change-waitlist-description-style-desktop"
                style={{ "width": "350px", "margin-left": ".75em", "margin-top": "-1em" }}
              >
                <div
                  className="d-flex flex-row align-items-center justify-content-center p-1"
                >
                  <a
                    href='https://discord.gg/9EZmV5qBY5'
                    target="_blank"
                    rel="noreferrer"
                    className='me-4 footer-text-black'
                  >
                    <Discord style={{ "height": "23px", "width": "auto" }} />
                  </a>
                  <a
                    href='https://www.youtube.com/@Indemnigaming'
                    target="_blank"
                    rel="noreferrer"
                    className='me-4 footer-text-black'
                  >
                    <Youtube style={{ "height": "23px", "width": "auto" }} />
                  </a>
                  <a
                    href='https://twitter.com/Indemnigaming'
                    target="_blank"
                    rel="noreferrer"
                    className='me-4 footer-text-black'
                  >
                    <Twitter style={{ "height": "20px", "width": "auto" }} />
                  </a>
                  <a
                    href='https://www.instagram.com/indemnigaming'
                    target="_blank"
                    rel="noreferrer"
                    className='me-4 footer-text-black'
                  >
                    <Instagram style={{ "height": "19px", "width": "auto" }} />
                  </a>
                </div>
              </h4>
            </div>
            <div className="d-flex flex-column justify-content-between align-items-center game-details-flex-column-mobile ">
              <h3
                style={{ "font-size": "2.5em", "width": "100%", "text-decoration": "underline", "text-align": "center", "margin-bottom": "2em" }}
                className="barlow700Font-black"
              >
                About Us
              </h3>
              <h4
                className="p-1 d-flex flex-column text-center change-waitlist-description-style-desktop"
                style={{ "width": "350px", "margin-top": "-.5em", "margin-bottom": "0em" }}
              >
                <div className="me-2 px-2 pb-3 ask-a-gamer-explore-margin-padding-right-left-0-mobile">
                  <div className="me-2 px-2 explore-games-background ask-a-gamer-explore-margin-right-0-mobile">
                    <div
                      className="d-flex flex-column py-3 px-4 ask-a-gamer-game-list-border ask-a-gamer-explore-padding-left-right-halfem-mobile"
                      style={{ "background-color": "#fff", "z-index": "2" }}
                    >
                      <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div
                          className="d-inline-flex flex-column align-items-center flex-wrap ask-a-gamer-explore-flex-column-mobile"
                          style={{ "width": "100%" }}
                        >
                          <span
                            style={{ "font-size": "1em", "width": "100%" }}
                            className="interFont-black mb-2"
                          >
                            We are currently hard at work developing the app, so this page will be updated soon as we approach our closed access playtests in late May/early June. Should you want to chat with the team or community in the meantime and learn more - join our <a href='https://discord.gg/9EZmV5qBY5' target="_blank" rel="noreferrer">Discord!</a>
                          </span>
                          <hr
                            style={{ "width": "100%", "color": "black" }}
                            className="hr-ask-indemni-title-hr"
                          />
                          <span
                            style={{ "font-size": "1.3em", "width": "100%" }}
                            className="interFont-black"
                          >
                            Legal Disclaimer
                          </span>
                          <span
                            style={{ "font-size": ".65em", "width": "100%", "font-style": "italic", "color": "grey", }}
                            className="interFont-black mt-1"
                          >
                            (Updated March 27, 2025)
                          </span>
                          <span
                            style={{ "font-size": "1em", "width": "100%" }}
                            className="interFont-black mt-3"
                          >
                            <a href="/PrivacyPolicy.pdf" >Our Privacy Policy</a>
                            <br />
                            <br />
                            <a href="/TermsOfService.pdf" >Our Terms of Service</a>
                          </span>
                          <span
                            style={{ "font-size": ".85em", "width": "100%", "stroke": "black" }}
                            className="roadRageFont-pink mt-4"
                          >
                            Thanks for checking out Indemni! More coming soon!
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </h4>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default IndemniIOSAboutPage;
